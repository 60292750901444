import Api from "./../api/index"
const apiService = new Api()

export const getContestDetails = async (page=1) => {
    try {
        console.log('test')
        const { result, message } = await apiService.get(
            `api/contest?page=${page}`
        )
        return {
            result,
            message,
        }
    } catch (error) {
        return Promise.reject(error)
    }
}
